import './config/env-admin'
import $ from 'jquery'
import Bacon from 'baconjs'

import 'jquery.cookie'
import * as UserProfileDAO from './dao/user-profile-dao'
import Router from './router/router'
import * as GenericUtils from './utils/generic-utils'
import {setAppAttributes} from "./set-app-atributes";

window.$ = window.jQuery = $;
window.m = require('mithril');

$.fn.asEventStream = Bacon.$.asEventStream;

let params = GenericUtils.getParamsMapForQueryString(window.location.search.substring(1)) || {};
window.initParams = params || {};

let initApp = () => {
    window.env = window.env || {};
    window.env.adminApp = true;
    return setAppAttributes()
        .then(_ => UserProfileDAO.createSession());
};

// window.addEventListener("resize", () => Router.reloadCurrentRoute());

initApp()
    .finally(_ => {
        return Router.start();
    });

// MainRenderer.startApp(false)
//     .then(_ => initApp())
//     .then(_ => Router.start());

